@media (max-width: 767px) {
  
    .nestor-main-menu .navbar-toggle {
      border: 1px solid #00579f !important;
      background: #00579f !important;
      color: #fff !important;
    }
}

/*Button color changes*/
.sermon-load-more, .sermon-search-button {
    padding: 2px 35px !important;
    border-color: #00579f;
    color: #00579f;
  }
  
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
    border-color: #2588c9 !important;
    background-color: #2588c9 !important;
  }
  
  .btn-primary{
    border-color: #2588c9;
  }

/*Fix for overflowing text on gmaps*/
.gm-style-iw {
    width: auto !important;
    overflow: hidden !important;
    max-width: 180px;
    text-align: center;
   }
.no-list-style{
  list-style: none;
}

.size-24  { font-size: 24px; }

.ministryImg {
  max-height: 160px;
  max-width: 250px;
}

.latestSermon-img{
  width: 100% !important;
  height: 100% !important;
}

.crcaLogo{
  margin-left: 60px;
  margin-top: 25px;
}
@media (max-width: 1200px) {
  .crcaLogo{
  margin-left: 25px;
  }
}

.pathwayHouseContact>h5, .pathwayHouseContact>h6{
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 5px;
}

.sermon-page-image, .sermon-series-page-image{
  max-height: 400px;
}


@media (min-width: 600px) {
  .ministryImgSecond{
    margin-left: 150px;
  }
}